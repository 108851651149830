<template>
  <div>
    <div class="Info_page">
      <div class="content_wrap">
        <p class="title">{{ title }}</p>
        <div class="content" v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import list from "@/utils/case";
export default {
  name: "Info",
  data() {
    return {
      content: "",
      title: "",
      isNews: true,
    };
  },
  components: {},
  created() {
    this.render();
  },
  mounted() {},
  methods: {
    render() {
      if(this.$route.query.id){
        axios
        .get(
          "https://en-api-hk.unre.com/blogs/query/" + this.$route.query.id,
          {}
        )
        .then((response) => {
          this.content = response.data.data.zhContent;
          this.title = response.data.data.zhTitle;
        })
        .catch((error) => {
          console.log(error);
        });
      }else{
        list.forEach((element) => {
        if (element.id === this.$route.query.ca) {
          this.content = element.content;
          this.title = element.title;
          
        }
      });
      }
      
    },
  },
};
</script>

<style lang="less">
.Info_page {
  background: #edf1f7;
  padding-bottom: 0.25rem;
  .content_wrap {
    margin: 0.25rem auto 0;
    width: 7.81rem;
    padding: 0.28rem 0.63rem 0.38rem;
    background: #fff;
    p {
      text-align: left;
      line-height: 30px;
      // margin-bottom: 8px;
    }
    h1 {
      text-align: left;
      font-size: 20px;
      line-height: 48px;
      color: #02aeec;
    }
    h2{
      text-align: center;
      color: rgb(136, 136, 136);
      font-size: 16px;
    }
    img {
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
    .content {
      width: 6.53rem;
      margin: 0 auto;

      color: #666666;
      font-size: 16px;
    }
    .title {
      margin-bottom: 32px;
      color: #333333;
      text-align: center;
      font-weight: 600;
      line-height: 1.2;
      font-size: 32px;
    }
  }
}
@media screen and (max-width: 900px) {
  .Info_page {
    padding: 0;
    .breadcrumb_wrap {
      display: none;
    }
    .content_wrap {
      width: 10rem;
      padding: 0.28rem 0.43rem;
      .title {
        margin-bottom: 0.48rem;
        font-size: 0.43rem;
      }
      .content {
        width: 100%;
        line-height: 0.64rem;
        font-size: 0.37rem;
      }
      h1 {
        font-size: 0.37rem;
        line-height: 0.64rem;
      }
    }
  }
}
</style>